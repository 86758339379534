import _get3 from "lodash/get";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _get = _get3;

var _get2 = _interopRequireDefault(_get);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}
/**
 * Calls a function passing properties values as arguments.
 * @example
 * // example with polished
 * import styled from "styled-components";
 * import { darken } from "polished";
 * import { withProp, prop } from "styled-tools";
 *
 * const Button = styled.button`
 *   border-color: ${withProp(prop("theme.primaryColor", "blue"), darken(0.5))};
 *   font-size: ${withProp("theme.size", size => `${size + 1}px`)};
 *   background: ${withProp(["foo", "bar"], (foo, bar) => `${foo}${bar}`)};
 * `;
 */


var withProp = function withProp(needle, fn) {
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (Array.isArray(needle)) {
      var needles = needle.map(function (arg) {
        return withProp(arg, function (x) {
          return x;
        })(props);
      });
      return fn.apply(undefined, _toConsumableArray(needles));
    }

    if (typeof needle === "function") {
      return fn(needle(props));
    }

    return fn((0, _get2.default)(props, needle));
  };
};

exports.default = withProp;
export default exports;