import _get3 from "lodash/get";
import _at3 from "lodash/at";
import _values3 from "lodash/values";
import _difference3 from "lodash/difference";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

var _get = _get3;

var _get2 = _interopRequireDefault(_get);

var _at = _at3;

var _at2 = _interopRequireDefault(_at);

var _values = _values3;

var _values2 = _interopRequireDefault(_values);

var _difference = _difference3;

var _difference2 = _interopRequireDefault(_difference);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Returns `pass` if prop is truthy. Otherwise returns `fail`
 * @example
 * // usage with styled-theme
 * import styled from "styled-components";
 * import { ifProp } from "styled-tools";
 * import { palette } from "styled-theme";
 *
 * const Button = styled.button`
 *   background-color: ${ifProp("transparent", "transparent", palette(0))};
 *   color: ${ifProp(["transparent", "accent"], palette("secondary", 0))};
 *   font-size: ${ifProp({ size: "large" }, "20px", ifProp({ size: "medium" }, "16px", "12px"))};
 * `;
 */


var ifProp = function ifProp(test, pass, fail) {
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var result = void 0;

    if (Array.isArray(test)) {
      result = !(0, _at2.default)(props, test).filter(function (value) {
        return !value;
      }).length;
    } else if (typeof test === "function") {
      result = test(props);
    } else if ((typeof test === "undefined" ? "undefined" : _typeof(test)) === "object") {
      var testKeys = Object.keys(test);
      var testValues = (0, _values2.default)(test);
      result = !(0, _difference2.default)((0, _at2.default)(props, testKeys), testValues).length;
    } else {
      result = (0, _get2.default)(props, test);
    }

    return result ? pass : fail;
  };
};

exports.default = ifProp;
export default exports;