var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var mapRules = function mapRules(props) {
  return function (part) {
    if (typeof part === "function") {
      return mapRules(props)(part(props));
    }

    if (Array.isArray(part)) {
      return part.map(mapRules(props)).join("");
    }

    return part;
  };
};
/**
 * Get the style rules from a given styled component.
 *
 * @example
 * import styled from "styled-components";
 * import { getStyle, prop } from "styled-tools";
 *
 * const Background = styled.div`
 *   display: block;
 *   background-color: ${prop("bg")};
 * `;
 *
 * const BlackBackground = styled.div`
 *   ${getStyle(Background, { bg: "black" })};
 * `;
 */


var getStyle = function getStyle() {
  var styledComponent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (!styledComponent.componentStyle) {
    throw new Error("[styled-tools] getStyle expects to receive a styled component.");
  }

  return styledComponent.componentStyle.rules.map(mapRules(_extends({}, styledComponent.defaultProps, props))).join("");
};

exports.default = getStyle;
export default exports;