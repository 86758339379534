import _ifProp3 from "./ifProp";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _ifProp = _ifProp3;

var _ifProp2 = _interopRequireDefault(_ifProp);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Returns `pass` if prop is falsy. Otherwise returns `fail`
 * @example
 * const Button = styled.button`
 *   font-size: ${ifNotProp("large", "20px", "30px")};
 * `;
 */


var ifNotProp = function ifNotProp(test, pass, fail) {
  return (0, _ifProp2.default)(test, fail, pass);
};

exports.default = ifNotProp;
export default exports;