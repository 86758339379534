import _get3 from "lodash/get";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _get = _get3;

var _get2 = _interopRequireDefault(_get);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Returns the value of `props[path]` or `defaultValue`
 * @example
 * const Button = styled.button`
 *  color: ${prop("color", "red")};
 * `;
 */


var prop = function prop(path, defaultValue) {
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return (0, _get2.default)(props, path, defaultValue);
  };
};

exports.default = prop;
export default exports;