import _get3 from "lodash/get";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _get = _get3;

var _get2 = _interopRequireDefault(_get);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Switches on a given prop. Returns the value or function for a given prop value.
 * @example
 * import styled, { css } from "styled-components";
 * import { switchProp, prop } from "styled-tools";
 *
 * const Button = styled.button`
 *  font-size: ${switchProp(prop("size", "medium"), {
 *    small: prop("theme.sizes.sm", "12px"),
 *    medium: prop("theme.sizes.md", "16px"),
 *    large: prop("theme.sizes.lg", "20px")
 *  }};
 *  ${switchProp("theme.kind", {
 *    light: css`
 *      color: LightBlue;
 *    `,
 *    dark: css`
 *      color: DarkBlue;
 *    `
 *  })}
 * `;
 *
 * <Button size="large" theme={{ kind: "light" }} />
 */


var switchProp = function switchProp(needle, switches) {
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var value = typeof needle === "function" ? needle(props) : (0, _get2.default)(props, needle);
    return (0, _get2.default)(switches, value);
  };
};

exports.default = switchProp;
export default exports;